.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.credit-card__img {
  /* display: block; */
  width: 40px;
  margin-right: 20px;
}

.btn-active-chart {
  color: #fff;
  background-color: #3577f1;
  border-color: transparent;
}

.row-margin-top {
  margin-top: 10px;
}

.chooseing-profile {
  display: flex;
  flex-direction: column;
  /* min-height: 50vh; */
  margin-top: 50px;
}

.chooseing-profile-select {
  margin-top: auto;
  margin: 6px 20px;
}

.chooseing-profile-title {
  padding: 12px 20px;
}

.multiline-placeholder::before {
  content: "\A";
  white-space: pre;
}

.btn {
  box-shadow: none;
}

.filter-table {
  padding: 0px 0px 0px 0px; 
}
.goZmTm {
  padding: 5px 0px 0px 0px !important;
}

.display-linebreak {
  white-space: pre-line;
}

.invalid-tooltip {
  left: 50%;
  transform: translateX(-50%);
  margin-top: -16px;
}

.p-fileupload-buttonbar .p-button {
  font-size: .8125rem;
  text-transform: uppercase;
}

.p-fileupload-content {
  font-size: 13px;
}

.p-message-wrapper {
  font-size: 13px;
}

.rdt_ExpanderRow pre {
  padding-left: 20px;
  background-color: lavender;
}

/* .rdt_ExpanderRow pre[data-theme="dark"] {    
  background-color: blueviolet;
} */

.row-border-bottom {
  border-bottom: 1px solid var(--vz-gray-300);
  padding-bottom: 16px;
}

.spinner-tab {
  position: absolute;
  right: 10px;
}

.chart-font {
  font-size: 10px;
}

.chart-message {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40vh; 
  text-align: center;  
}

#metrics-chart-date-range::placeholder {
  color: #3577f1;
}
#metrics-chart-date-range:hover::placeholder {
  color: #fff;
}
